import React, {useEffect, useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import RouterLink from 'react-metismenu-router-link'
import MetisMenu from "react-metismenu";
import netlifyIdentity from 'netlify-identity-widget'
import {loginUser, logoutUser} from "./utils/userAuthentication";
import SvgTester from "./pages/SvgTester";
import {Button} from "react-bootstrap";

const menu = [
    {
        label: "Home",
        to: "/"
    },
    {
        label: "SvgTester",
        to: "/svgTester"
    }
];

const App = (props) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const loggedInUser = localStorage.getItem("currentOpenSaucedUser");

        if (loggedInUser) {
            console.log("Is logged in");
            setUser( JSON.parse(loggedInUser));
        } else {
            console.log("Not logged in yet, logging in");
            loginUser();
        }

        netlifyIdentity.on("login", (user) => {
            setUser(user);
            loginUser()
        });
        netlifyIdentity.on("logout", () => {
            setUser(null);
            logoutUser()
        });
    }, []);

    console.log("Rendering App");

    if (user)
    {
        return (
            <div>
                <SvgTester/>
            </div>
        );
    }

    return(
        <div>
        </div>
    )
};

export default App;
