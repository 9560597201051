import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/base.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import {Route} from "react-router";

import Home from './pages/Home'
import SvgTester from './pages/SvgTester'
import {Switch} from "react-bootstrap";
import netlifyIdentity from "netlify-identity-widget";
import {IdentityContextProvider} from "react-netlify-identity-widget";

// If you want your app to work offline and load faster, you can change
netlifyIdentity.init();

ReactDOM.render(
    <IdentityContextProvider url={"https://blissful-turing-89a15e.netlify.com"}>
        <Router>
            <Route path="/" component={App} />
        </Router>
    </IdentityContextProvider>, document.getElementById('root'));
// unregister() to register() below. Note this comes with some pitfalls.

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
