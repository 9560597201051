import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {Card} from "react-bootstrap";

const SimpleReactFileUpload = (props) =>
{
    const onDrop = useCallback(acceptedFiles => {
        props.setFile(acceptedFiles[0])
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <Card {...getRootProps()}>
            <Card.Header>
                Upload you SVG here. Preview will be automatically updated.
            </Card.Header>
            <Card.Body>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag 'n' drop some files here, or click to select files</p>
            }
            </Card.Body>
        </Card>
    )
};

export default SimpleReactFileUpload