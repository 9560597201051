import React, {useState} from "react";
import SimpleReactFileUpload from "../components/FilfeUpload";
import SvgRenderer from "../components/SvgRenderer";
import MultiColorSelector from "../components/MultiColorSelector";

const SvgTester = () => {
    const [file, setFile] = useState(null);
    const [colors, setColors] = useState(["#00ff00", "#0000ff", "#ff0000", "#000000", "#ffff00", "#ffffff", "#ff00ff", "#00ffff"]);

    const colorSelected = (index, color) => {
        const newColors = [...colors];
        newColors[index] = color;

        setColors(newColors);
    };

    return (
        <div style={{maxWidth: "600px", margin: "auto"}}>
            <SimpleReactFileUpload setFile={setFile}/>
            <SvgRenderer file={file} backgroundColors={colors}/>
            <MultiColorSelector colors={colors} colorSelected={colorSelected}/>
        </div>
    )
};

export default SvgTester;
