export let ColorMapping = {};
export const InjectColors = (svg) => {
    let gs = svg.getElementsByTagName('g');
    let currentColorIdx = 0;
    ColorMapping = {};

    for (const g of gs) {
        let id = g.getAttribute('id');
        if (/^.-Ueberschrift$/.test(id))
            currentColorIdx = 0;

        if(/-Highlight/.test(id)){
            let rect = g.getElementsByTagName('rect')[0];
            let oldStyle = rect.getAttribute('style') ;

            rect.setAttribute('style', (oldStyle ? oldStyle : '') + 'fill:var(--fill-color-' + currentColorIdx + ');fill-rule:evenodd');
            ColorMapping[id.match(/(.*)-Highlight/)[1]] = currentColorIdx;
            currentColorIdx++;
        }
    }
};