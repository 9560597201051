import React from "react";
import SketchExample from "./SketchExample";

const ColorSelector = (props) => {

    return (
        <div>
            {"Index: " + props.index}
            <SketchExample {...props} />
        </div>
    )
};

export default ColorSelector;