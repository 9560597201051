import ColorSvgWrapper from "./ColorSvgTester";
import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";

const SvgRenderer = (props) => {
    const [oldFile, setOldFile] = useState(null);
    const [tmpUrl, setTmpUrl] = useState(null);
    const [toRender, setToRender] = useState(<div>No SVG selected</div>);

    useEffect(() => {
        if (props.file != null) {

            let url = tmpUrl;
            if(props.file !== oldFile || oldFile === null)
            {
                url = URL.createObjectURL(props.file) + "#.svg"; // Hack because it expects the filename / url to end with .svg
                setOldFile(props.file);

                setTmpUrl(url);
            }
            setToRender(<ColorSvgWrapper image={url} backgroundColors={props.backgroundColors}/>);
        }

    }, [props]);

    return (
        <Card>
            <Card.Header>
                SVG preview Renderer
            </Card.Header>
            <Card.Body style={{height: "100%"}}>
                {toRender}
            </Card.Body>
        </Card>
    );
};

export default SvgRenderer;