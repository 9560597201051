import React from "react";
import ColorSelector from "./ColorSelector";
import {Card, Col, Row} from "react-bootstrap";

const MultiColorSelector = (props) => {
    const constToRender = props.colors.map((color, index) => (
        <Col lg={3}>
            <ColorSelector color={color} index={index} colorSelected={(color) => props.colorSelected(index, color)}/>
        </Col>)
    );

    return (
        <Card>
            <Card.Header>
                Color Selection
            </Card.Header>
            <Card.Body>
                <Row>
                    {constToRender}
                </Row>
            </Card.Body>
        </Card>
    )
};

export default MultiColorSelector;
