import { ReactSVG } from "react-svg";
import Loader from "react-loaders";
import React from "react";
import { InjectColors, ColorMapping } from "./ColorInjector";

const ColorSvgWrapper = (props) => {
    const colorStyles = {};
    if (props.backgroundColors !== undefined) {
        props.backgroundColors.forEach((colorValue, index) => {
            const propName = "--fill-color-" + index;
            colorStyles[propName] = colorValue
        });
    }

    const style = {
        maxWidth: '100%',
        maxHeight: "300px",
        fill: "#ffffff",
        ...colorStyles
    };

    function downloadSVG(svg) {
        // if(typeof svg !== 'Node')
        //     return alert('No svg rendered');

        try {
            var serializer = new XMLSerializer();
            var source = serializer.serializeToString(svg);
        } catch (e) {
            alert(e);
            return;
        }


        //add name spaces.
        if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        //add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        // let cs = getComputedStyle(svg)

        // var varDetectorRe = /var\(([^\)]+)\)/g;
        // var result;
        // while ((result = varDetectorRe.exec(source)) !== null) {
        //     source = source.replace(result[0], cs.getPropertyValue(result[1])); //color variable replacing
        // }

        const element = document.createElement("a");
        const file = new Blob([source], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "graphic.svg";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    let svgC = null;

    return (<>
        <ReactSVG id={"SVG-image"}
            src={props.image}
            type="image/svg+xml"
            style={style}
            loading={() => <Loader type="ball-scale-ripple" active />}
            className="SVG-WRAPPER"
            wrapper={"div"}
            afterInjection={(error, svg) => {
                if (error) {
                    console.error(error)
                    return;
                }
                InjectColors(svg);
                svgC = svg;
            }}
            beforeInjection={svg => {
                console.log("Before Injection: ", svg);
                svg.classList.add('svg-class-name');
            }}
            evalScripts="never"
        />
        <button onClick={() => downloadSVG(svgC)}>Download svg</button>
    </>
    );

};

export default ColorSvgWrapper;